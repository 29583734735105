export interface IBreadCrumb {
  clickTo: string;
  title: string;
  type: string;
  subtitle: string;
}

export const BreadCrumbList: { [key: string]: IBreadCrumb } = {
  // PAYROLL
  '/payroll-run/detail': {
    clickTo: '/payroll-run',
    title: 'Run Payroll',
    type: 'list',
    subtitle: 'Regular Payroll',
  },
  '/payroll/setup/setup-payroll/detail': {
    clickTo: '/payroll/setup/setup-payroll',
    title: 'Pay Group',
    type: 'list',
    subtitle: 'Setup Details',
  },
  '/payroll/setup/pay-item/detail': {
    clickTo: '/payroll/setup/pay-item',
    title: 'Pay Item',
    type: 'list',
    subtitle: 'Pay Item Details',
  },
  '/payroll/setup/pay-item/create': {
    clickTo: '/payroll/setup/pay-item',
    title: 'Pay Item',
    type: 'list',
    subtitle: 'Create Pay Item',
  },
  '/payroll/setup/pay-item/update': {
    clickTo: '/payroll/setup/pay-item/detail',
    title: 'Pay Item Details',
    type: 'detail',
    subtitle: 'Update Pay Item',
  },
  '/payroll/setup/pay-item/correction': {
    clickTo: '/payroll/setup/pay-item/detail',
    title: 'Pay Item Details',
    type: 'detail',
    subtitle: 'Correction Pay Item',
  },
  '/payroll/setup/setup-payroll/edit': {
    clickTo: '/payroll/setup/setup-payroll/detail',
    title: 'Pay Group',
    type: 'detail',
    subtitle: 'Update Setup',
  },
  '/payroll/compensation/detail': {
    clickTo: '/payroll/compensation',
    title: 'Employee Compensation',
    type: 'list',
    subtitle: 'Employee Details',
  },
  '/employee/detail': {
    clickTo: '/employee',
    title: 'Employee',
    type: 'list',
    subtitle: 'Employee Details',
  },
  '/employee/detail/work-location/create': {
    clickTo: '/employee/detail/:id/work-location',
    title: 'Employee Details',
    type: 'list',
    subtitle: 'Create Work Location',
  },
  '/employee/detail/work-location/update': {
    clickTo: '/employee/detail/:id/work-location',
    title: 'Employee Details',
    type: 'list',
    subtitle: 'Update Work Location',
  },
  '/employee/detail/work-location/correction': {
    clickTo: '/employee/detail/:id/work-location',
    title: 'Employee Details',
    type: 'list',
    subtitle: 'Correct Work Location Info',
  },
  '/employee/detail/personal-info/update': {
    clickTo: '/employee/detail/:id/personal-info',
    title: 'Employee Details',
    type: 'list',
    subtitle: 'Update Personal Info',
  },
  '/employee/detail/personal-info/correction': {
    clickTo: '/employee/detail/:id/personal-info',
    title: 'Employee Details',
    type: 'list',
    subtitle: 'Correct Personal Info',
  },
  '/employee/detail/address/create': {
    clickTo: '/employee/detail/:id/address',
    title: 'Employee Details',
    type: 'list',
    subtitle: 'Add Address',
  },
  '/employee/detail/address/update': {
    clickTo: '/employee/detail/:id/address',
    title: 'Employee Details',
    type: 'list',
    subtitle: 'Update Address',
  },
  '/employee/detail/address/correction': {
    clickTo: '/employee/detail/:id/address',
    title: 'Employee Details',
    type: 'list',
    subtitle: 'Correct Address',
  },
  '/employee/detail/statutory/create': {
    clickTo: '/employee/detail/:id/statutory',
    title: 'Employee Details',
    type: 'list',
    subtitle: 'Add Statutory Info',
  },
  '/employee/detail/statutory/update': {
    clickTo: '/employee/detail/:id/statutory',
    title: 'Employee Details',
    type: 'list',
    subtitle: 'Update Statutory',
  },
  '/employee/detail/statutory/correction': {
    clickTo: '/employee/detail/:id/statutory',
    title: 'Employee Details',
    type: 'list',
    subtitle: 'Correct Statutory',
  },
  '/employee/create': {
    clickTo: '/employee',
    title: 'Employee',
    type: 'list',
    subtitle: 'Create Employee',
  },
  '/employee/export': {
    clickTo: '/employee',
    title: 'Employee',
    type: 'list',
    subtitle: 'Export Employee Data',
  },
  '/employee/correction/personal': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Correction of Personal Data',
  },
  '/employee/correction/statutory': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Correction of Statutory',
  },
  '/employee/detail/employment/update': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Update Employment Info',
  },
  '/employee/detail/employment/correction': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Correct Employment Info',
  },
  '/employee/experience-create': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Add Experience',
  },
  '/employee/experience-update': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Update Experience',
  },
  '/employee/experience-correction': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Correct Experience',
  },
  '/employee/family-create': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Add Family',
  },
  '/employee/family-update': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Update Family',
  },
  '/employee/family-correction': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Correct Family',
  },
  '/payroll/payroll-run/regular/run': {
    clickTo: '/payroll/payroll-run',
    title: 'Run Payroll',
    type: 'list',
    subtitle: 'Regular Payroll',
  },
  '/payroll/payroll-run/off-cycle/run': {
    clickTo: '/payroll/payroll-run',
    title: 'Run Payroll',
    type: 'list',
    subtitle: 'Off-Cycle Payroll',
  },
  '/payroll/payroll-run/one-time-payment': {
    clickTo: '/payroll/payroll-run',
    title: 'Run Payroll',
    type: 'list',
    subtitle: 'One-time Payment',
  },
  '/payroll/payroll-run/one-time-payment/create': {
    clickTo: '/payroll/payroll-run/one-time-payment',
    title: 'One-time Payment',
    type: 'list',
    subtitle: 'Create One-time Payment',
  },
  '/payroll/setup/setup-payroll/create': {
    clickTo: '/payroll/setup/setup-payroll',
    title: 'Pay Group',
    type: 'list',
    subtitle: 'Create  Pay Group',
  },
  '/payroll/payroll-run/regular/detail': {
    clickTo: '/payroll/payroll-run/',
    title: 'Run Payroll',
    type: 'list',
    subtitle: 'Regular Payroll',
  },
  '/payroll/payroll-run/off-cycle/detail': {
    clickTo: '/payroll/payroll-run/',
    title: 'Run Payroll',
    type: 'list',
    subtitle: 'Off-Cycle Payroll',
  },
  '/payroll/payroll-run/regular/detail/payment-detail': {
    clickTo: '/payroll/payroll-run/regular/detail',
    title: 'Regular Payroll',
    type: 'create',
    subtitle: 'Payment Details',
  },
  '/payroll/payroll-run/off-cycle/detail/payment-detail': {
    clickTo: '/payroll/payroll-run/off-cycle/detail',
    title: 'Off-Cycle Payroll',
    type: 'create',
    subtitle: 'Payment Details',
  },
  '/payroll/payroll-run/regular/detail/payment-detail/create': {
    clickTo: '/payroll/payroll-run/regular/detail/payment-detail',
    title: 'Payment Details',
    type: 'create',
    subtitle: 'Create One-time Payment',
  },
  '/payroll/payroll-run/off-cycle/detail/payment-detail/create': {
    clickTo: '/payroll/payroll-run/off-cycle/detail/payment-detail',
    title: 'Payment Details',
    type: 'create',
    subtitle: 'Create One-time Payment',
  },
  // JOB GRADE DETAIL:
  '/organization/job-grade/detail': {
    clickTo: '/organization/job-grade',
    title: 'Job Grade',
    type: 'list',
    subtitle: 'Job Grade Details',
  },
  // JOB GRADE CREATE:
  '/organization/job-grade/create': {
    clickTo: '/organization/job-grade',
    title: 'Job Grade',
    type: 'list',
    subtitle: 'Create Job Grade',
  },
  // JOB GRADE CORRECTION:
  '/organization/job-grade/correction': {
    clickTo: '/organization/job-grade/detail',
    title: 'Job Grade Details',
    type: 'detail',
    subtitle: 'Correction Job Grade',
  },
  // JOB GRADE UPDATE:
  '/organization/job-grade/update': {
    clickTo: '/organization/job-grade/detail',
    title: 'Job Grade Details',
    type: 'detail',
    subtitle: 'Update Job Grade',
  },
  // JOB POSITION DETAIL:
  '/organization/job-position/detail': {
    clickTo: '/organization/job-position',
    title: 'Job Position',
    type: 'list',
    subtitle: 'Job Position Details',
  },
  // JOB POSITION CREATE;
  '/organization/job-position/create': {
    clickTo: '/organization/job-position',
    title: 'Job Position',
    type: 'list',
    subtitle: 'Create Job Position',
  },
  // JOB POSITION UPDATE;
  '/organization/job-position/update': {
    clickTo: '/organization/job-position/detail',
    title: 'Job Position Details',
    type: 'detail',
    subtitle: 'Update Job Position',
  },
  // JOB POSITION CORRECTION;
  '/organization/job-position/correction': {
    clickTo: '/organization/job-position/detail',
    title: 'Job Position Details',
    type: 'detail',
    subtitle: 'Correction Job Position',
  },
  // ORGANIZATION UNIT DETAIL
  '/organization/organization-unit/detail': {
    clickTo: '/organization/organization-unit',
    title: 'Organization Unit',
    type: 'list',
    subtitle: 'Organization Unit Details',
  },
  // ORGANIZATION UNIT CREATE
  '/organization/organization-unit/create': {
    clickTo: '/organization/organization-unit',
    title: 'Organization Unit ',
    type: 'list',
    subtitle: 'Create Organization Unit',
  },
  // ORGANIZATION UNIT UPDATE
  '/organization/organization-unit/update': {
    clickTo: '/organization/organization-unit/detail',
    title: 'Organization Unit Details ',
    type: 'detail',
    subtitle: ' Update Organization Unit ',
  },
  // ORGANIZATION UNIT CORRECTION
  '/organization/organization-unit/correction': {
    clickTo: '/organization/organization-unit/detail',
    title: 'Organization Unit Details ',
    type: 'detail',
    subtitle: ' Correct Organization Unit ',
  },
  // WORK LOCATION LIST
  '/organization/work-location/detail': {
    clickTo: '/organization/work-location',
    title: 'Work Location',
    type: 'list',
    subtitle: 'Work Location Details ',
  },
  // WORK LOCATION CREATE
  '/organization/work-location/create': {
    clickTo: '/organization/work-location',
    title: 'Work Location',
    type: 'list',
    subtitle: 'Create Work Location',
  },
  // WORK LOCATION CORRECTION
  '/organization/work-location/correction': {
    clickTo: '/organization/work-location/detail',
    title: 'Work Location Details',
    type: 'detail',
    subtitle: 'Correction Work Location',
  },
  // WORK LOCATION UPDATE
  '/organization/work-location/update': {
    clickTo: '/organization/work-location/detail',
    title: 'Work Location Details',
    type: 'detail',
    subtitle: 'Update Work Location ',
  },
  // ORGANIZATION STRUCTURE DETAIL
  '/organization/organization-structure/detail': {
    clickTo: '/organization/organization-structure',
    title: 'Organization Structure',
    type: 'list',
    subtitle: 'Organization Structure Details',
  },
  // ORGANIZATION STRUCTURE CREATE
  '/organization/organization-structure/create': {
    clickTo: '/organization/organization-structure',
    title: 'Organization Structure ',
    type: 'list',
    subtitle: 'Create Organization Structure',
  },
  // ORGANIZATION STRUCTURE UPDATE
  '/organization/organization-structure/update': {
    clickTo: '/organization/organization-structure/detail',
    title: 'Organization Structure Details',
    type: 'detail',
    subtitle: 'Update Organization Structure ',
  },
  // ORGANIZATION STRUCTURE CORRECTION
  '/organization/organization-structure/correction': {
    clickTo: '/organization/organization-structure/detail',
    title: 'Organization Structure Details',
    type: 'detail',
    subtitle: 'Correction Organization Structure ',
  },
  // EMPLOYMENT TYPE CREATE
  '/master-data/employment-type/create': {
    clickTo: '/master-data/employment-type',
    title: 'Employment Type ',
    type: 'list',
    subtitle: 'Create Employment Type ',
  },
  // EMPLOYMENT TYPE DETAIL
  '/master-data/employment-type/detail': {
    clickTo: '/master-data/employment-type',
    title: 'Employment Type ',
    type: 'list',
    subtitle: 'Employment Type Details ',
  },
  // EMPLOYMENT TYPE UPDATE
  '/master-data/employment-type/update': {
    clickTo: '/master-data/employment-type/detail',
    title: 'Employment Type Details ',
    type: 'detail',
    subtitle: 'Update Employment Type',
  },
  // EMPLOYMENT TYPE CORRECTION
  '/master-data/employment-type/correction': {
    clickTo: '/master-data/employment-type/detail',
    title: 'Employment Type Details ',
    type: 'detail',
    subtitle: 'Correct Employment Type',
  },
  // VALUE ROUNDING CREATE
  '/payroll/configuration/value-rounding/create': {
    clickTo: '/payroll/configuration/value-rounding',
    title: 'Value Rounding',
    type: 'list',
    subtitle: 'Create Value Rounding',
  },
  // VALUE ROUNDING DETAIL
  '/payroll/configuration/value-rounding/detail': {
    clickTo: '/payroll/configuration/value-rounding',
    title: 'Value Rounding ',
    type: 'list',
    subtitle: 'Value Rounding Details',
  },
  // VALUE ROUNDING UPDATE
  '/payroll/configuration/value-rounding/update': {
    clickTo: '/payroll/configuration/value-rounding/detail',
    title: 'Value Rounding Details',
    type: 'detail',
    subtitle: 'Update Value Rounding',
  },
  // ACCUMULATOR
  '/payroll/configuration/accumulator/create': {
    clickTo: '/payroll/configuration/accumulator',
    title: 'Accumulator',
    type: 'list',
    subtitle: 'Create Accumulator',
  },
  '/payroll/configuration/accumulator/detail': {
    clickTo: '/payroll/configuration/accumulator',
    title: 'Accumulator',
    type: 'list',
    subtitle: 'Accumulator Details',
  },
  '/payroll/configuration/accumulator/update': {
    clickTo: '/payroll/configuration/accumulator/detail',
    title: 'Accumulator Details',
    type: 'detail',
    subtitle: 'Update Accumulator',
  },
  '/payroll/configuration/accumulator/correction': {
    clickTo: '/payroll/configuration/accumulator/detail',
    title: 'Accumulator Details',
    type: 'detail',
    subtitle: 'Correction Accumulator',
  },
  // LEGAL ENTITY
  '/organization/legal-entity/detail': {
    clickTo: '/organization/legal-entity',
    title: 'Legal Entity',
    type: 'list',
    subtitle: 'Legal Entity Details',
  },
  '/organization/legal-entity/create': {
    clickTo: '/organization/legal-entity',
    title: 'Legal Entity',
    type: 'list',
    subtitle: 'Create Legal Entity',
  },
  '/organization/legal-entity/update': {
    clickTo: '/organization/legal-entity/detail',
    title: 'Legal Entity Details',
    type: 'detail',
    subtitle: 'Update Legal Entity',
  },
  '/organization/legal-entity/correction': {
    clickTo: '/organization/legal-entity/detail',
    title: 'Legal Entity Details',
    type: 'detail',
    subtitle: 'Correction Legal Entity',
  },
  '/organization/legal-entity/create-statutory': {
    clickTo: '/organization/legal-entity/detail',
    title: 'Legal Entity Details',
    type: 'detail',
    subtitle: 'Add Statutory',
  },
  '/organization/legal-entity/bank-create': {
    clickTo: '/organization/legal-entity/detail',
    title: 'Legal Entity Details',
    type: 'detail',
    subtitle: 'Add Bank',
  },
  '/organization/legal-entity/bank-update': {
    clickTo: '/organization/legal-entity/detail',
    title: 'Legal Entity Details',
    type: 'detail',
    subtitle: 'Update Bank',
  },
  '/organization/legal-entity/bank-correction': {
    clickTo: '/organization/legal-entity/detail',
    title: 'Legal Entity Details',
    type: 'detail',
    subtitle: 'Correct Bank',
  },
  '/organization/legal-entity/detail/statutories/create': {
    clickTo: '/organization/legal-entity/detail',
    title: 'Legal Entity Details',
    type: 'detail',
    subtitle: 'Add Statutory',
  },
  '/organization/legal-entity/detail/statutories/update': {
    clickTo: '/organization/legal-entity/detail',
    title: 'Legal Entity Details',
    type: 'detail',
    subtitle: 'Update Statutory',
  },
  '/organization/legal-entity/detail/statutories/correction': {
    clickTo: '/organization/legal-entity/detail',
    title: 'Legal Entity Details',
    type: 'detail',
    subtitle: 'Correct Statutory',
  },
  '/ess/employee/details': {
    clickTo: '/ess/employee',
    title: 'Home',
    type: 'list',
    subtitle: 'My Profile',
  },
  // SETTINGS
  '/custom-field/create': {
    clickTo: '/custom-field',
    title: 'Custom Fields',
    type: 'list',
    subtitle: 'Create Custom Fields',
  },
  '/custom-field/detail': {
    clickTo: '/custom-field',
    title: 'Custom Fields',
    type: 'list',
    subtitle: 'Custom Field Details',
  },
  '/custom-field/update': {
    clickTo: '/custom-field/detail',
    title: 'Custom Fields Detail',
    type: 'detail',
    subtitle: 'Update Custom Fields',
  },
  // EXCHANGE RATE
  '/payroll/configuration/exchange-rate/create': {
    clickTo: '/payroll/configuration/exchange-rate',
    title: 'Exchange Rate',
    type: 'list',
    subtitle: 'Create Exchange Rate',
  },
  '/payroll/configuration/exchange-rate/detail': {
    clickTo: '/payroll/configuration/exchange-rate',
    title: 'Exchange Rate ',
    type: 'list',
    subtitle: 'Exchange Rate Details',
  },
  '/payroll/configuration/exchange-rate/update': {
    clickTo: '/payroll/configuration/exchange-rate/detail',
    title: 'Exchange Rate Details ',
    type: 'detail',
    subtitle: 'Update Exchange Rate',
  },
  '/payroll/configuration/exchange-rate/correction': {
    clickTo: '/payroll/configuration/exchange-rate/detail',
    title: 'Exchange Rate Details ',
    type: 'detail',
    subtitle: 'Correction Exchange Rate',
  },
  '/master-data/cost-center/create': {
    clickTo: '/master-data/cost-center',
    title: 'Cost Center',
    type: 'list',
    subtitle: 'Create Cost Center',
  },
  '/master-data/cost-center/detail': {
    clickTo: '/master-data/cost-center',
    title: 'Cost Center',
    type: 'list',
    subtitle: 'Cost Center Details',
  },
  '/master-data/cost-center/update': {
    clickTo: '/master-data/cost-center/detail',
    title: 'Cost Center Details',
    type: 'detail',
    subtitle: 'Update Cost Center',
  },
  '/master-data/cost-center/correction': {
    clickTo: '/master-data/cost-center/detail',
    title: 'Cost Center Details',
    type: 'detail',
    subtitle: 'Correct Cost Center',
  },
  '/payroll/configuration/statutory/social-healthcare/detail': {
    clickTo: '/payroll/configuration/statutory',
    title: 'Statutory',
    type: 'list',
    subtitle: 'Social Healthcare Details',
  },
  '/payroll/configuration/statutory/social-security/detail': {
    clickTo: '/payroll/configuration/statutory',
    title: 'Statutory',
    type: 'list',
    subtitle: 'Social Security Details',
  },
  '/payroll/configuration/statutory/tax/detail': {
    clickTo: '/payroll/configuration/statutory',
    title: 'Statutory',
    type: 'list',
    subtitle: 'Tax Details',
  },
  '/payroll/configuration/statutory/ter/detail': {
    clickTo: '/payroll/configuration/statutory',
    title: 'Statutory',
    type: 'list',
    subtitle: 'Tax Details',
  },
  '/payroll/configuration/statutory/ter-harian/detail': {
    clickTo: '/payroll/configuration/statutory',
    title: 'Statutory',
    type: 'list',
    subtitle: 'Tax Details',
  },
  '/payroll/configuration/statutory/severancePay/detail': {
    clickTo: '/payroll/configuration/statutory',
    title: 'Statutory',
    type: 'list',
    subtitle: 'Tax Details',
  },
  '/payroll/configuration/statutory/non-taxable-income/detail': {
    clickTo: '/payroll/configuration/statutory',
    title: 'Statutory',
    type: 'list',
    subtitle: 'Non-Taxable Income Details',
  },
  '/employee/detail/cost-center/create': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Add Cost Center',
  },
  '/employee/detail/cost-center/correction': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Correct Cost Center',
  },
  '/employee/detail/cost-center/update': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Update Cost Center',
  },
  '/employee/detail/bank/create': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Add Bank',
  },
  '/employee/detail/bank/update': {
    clickTo: '/employee/detail',
    title: 'Bank Info Details',
    type: 'detail',
    subtitle: 'Update Bank',
  },
  '/employee/detail/bank/correction': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Correct Bank',
  },
  '/employee/detail/education/create': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Add Education',
  },
  '/employee/detail/education/update': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Update Education',
  },
  '/employee/detail/education/correction': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Correct Education',
  },
  '/employee/detail/files/create': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Add Files Info',
  },
  '/employee/detail/files/update': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Update Files Info',
  },
  '/employee/detail/files/correction': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Correct Files Info',
  },
  '/employee/detail/earning/update': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Update Earnings',
  },
  '/employee/detail/earning/correction': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Correction Earnings',
  },
  '/employee/detail/deduction/update': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Update Deduction',
  },
  '/employee/detail/deduction/correction': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Correction Deduction',
  },
  '/employee/detail/previous-employment/create': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'list',
    subtitle: 'Add Previous Employment',
  },
  '/employee/detail/previous-employment/update': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'list',
    subtitle: 'Update Previous Employment',
  },
  '/employee/detail/previous-employment/correction': {
    clickTo: '/employee/detail',
    title: 'Previous Employment',
    type: 'list',
    subtitle: 'Correct Previous Employment',
  },
  // PAYROLL INFO
  '/employee/detail/payrol-info/create': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'list',
    subtitle: 'Add Payroll Info',
  },
  '/employee/detail/payrol-info/update': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'list',
    subtitle: 'Update Payroll Info',
  },
  '/employee/detail/payrol-info/correction': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'list',
    subtitle: 'Correct Payroll Info',
  },
  // USER ACCESS
  '/security/user-access/create': {
    clickTo: '/security/user-access',
    title: 'User Access',
    type: 'list',
    subtitle: 'Create User Access',
  },
  '/security/user-access/create/internal': {
    clickTo: '/security/user-access/create',
    title: 'User Access',
    type: 'list',
    subtitle: 'Create User Access',
  },
  '/security/user-access/create/external': {
    clickTo: '/security/user-access/create',
    title: 'User Access',
    type: 'list',
    subtitle: 'Create User Access',
  },
  '/security/user-access/detail': {
    clickTo: '/security/user-access',
    title: 'User Access',
    type: 'list',
    subtitle: 'User Access Details',
  },
  '/workflows/dynamic-group/create': {
    clickTo: '/workflows/dynamic-group',
    title: 'Dynamic Group',
    type: 'list',
    subtitle: 'Dynamic Group Create',
  },
  '/workflows/dynamic-group/update': {
    clickTo: '/workflows/dynamic-group/detail',
    title: 'Dynamic Group',
    type: 'detail',
    subtitle: 'Dynamic Group Update',
  },
  '/workflows/dynamic-group/detail': {
    clickTo: '/workflows/dynamic-group',
    title: 'Dynamic Group',
    type: 'list',
    subtitle: 'Dynamic Group Detail',
  },
  '/payroll/configuration/formula/create': {
    clickTo: '/payroll/configuration/formula',
    title: 'Formula',
    type: 'list',
    subtitle: 'Create Formula',
  },
  '/workflows/workflow/update': {
    clickTo: '/workflows/workflow/detail',
    title: 'Workflow',
    type: 'detail',
    subtitle: 'Workflow Update',
  },
  '/workflows/workflow/correction': {
    clickTo: '/workflows/workflow/detail',
    title: 'Workflow',
    type: 'detail',
    subtitle: 'Workflow Correction',
  },
  '/workflows/workflow/detail': {
    clickTo: '/workflows/workflow',
    title: 'Workflow',
    type: 'list',
    subtitle: 'Workflow Detail',
  },
  '/workflows/workflow/create': {
    clickTo: '/workflows/workflow',
    title: 'Workflow ',
    type: 'list',
    subtitle: 'Create Workflow',
  },
  '/payroll/configuration/formula/detail': {
    clickTo: '/payroll/configuration/formula',
    title: 'Formula',
    type: 'list',
    subtitle: 'Formula Details',
  },
  '/payroll/configuration/formula/update': {
    clickTo: '/payroll/configuration/formula/detail',
    title: 'Formula Details',
    type: 'detail',
    subtitle: 'Update Formula',
  },
  '/payroll/configuration/formula/correction': {
    clickTo: '/payroll/configuration/formula/detail',
    title: 'Formula Details',
    type: 'detail',
    subtitle: 'Correction Formula',
  },
  '/employee/detail/payment-details/regular-payment/update': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Update Regular Payments',
  },
  '/employee/detail/payment-details/offcycle-payment/update': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Update Off-Cycle Payments',
  },
  // ACCESS CONTROL
  '/security/access-control/create': {
    clickTo: '/security/access-control',
    title: 'Access Control',
    type: 'list',
    subtitle: 'Create Access Control',
  },
  '/security/access-control/detail': {
    clickTo: '/security/access-control',
    title: 'Detail Access Control',
    type: 'list',
    subtitle: 'Detail Access Control',
  },
  '/security/access-control/update': {
    clickTo: '/security/access-control/detail',
    title: 'Detail Access Control',
    type: 'detail',
    subtitle: 'Update Access Control',
  },
  // JOB LEVEL DETAIL:
  '/organization/job-level/detail': {
    clickTo: '/organization/job-level',
    title: 'Job Level',
    type: 'list',
    subtitle: 'Job Level Details',
  },
  // JOB LEVEL CREATE:
  '/organization/job-level/create': {
    clickTo: '/organization/job-level',
    title: 'Job Level',
    type: 'list',
    subtitle: 'Create Job Level',
  },
  // JOB LEVEL CORRECTION:
  '/organization/job-level/correction': {
    clickTo: '/organization/job-level/detail',
    title: 'Job Level Details',
    type: 'detail',
    subtitle: 'Correction Job Level',
  },
  // JOB LEVEL UPDATE:
  '/organization/job-level/update': {
    clickTo: '/organization/job-level/detail',
    title: 'Job Level Details',
    type: 'detail',
    subtitle: 'Update Job Level',
  },
  // CHART OF ACCOUNT DETAIL:
  '/accounting/chart-of-accounts/detail': {
    clickTo: '/accounting/chart-of-accounts',
    title: 'Chart of Accounts',
    type: 'list',
    subtitle: 'Account Details',
  },
  // CHART OF ACCOUNT CREATE:
  '/accounting/chart-of-accounts/create': {
    clickTo: '/accounting/chart-of-accounts',
    title: 'Chart of Accounts',
    type: 'list',
    subtitle: 'Create Account',
  },
  // CHART OF ACCOUNT UPDATE:
  '/accounting/chart-of-accounts/update': {
    clickTo: '/accounting/chart-of-accounts/detail',
    title: 'Chart Of Account Details',
    type: 'detail',
    subtitle: 'Update Chart Of Account',
  },
  // TAX LOCATION DETAIL:
  '/master-data/tax-location/detail': {
    clickTo: '/master-data/tax-location',
    title: 'Tax Location',
    type: 'list',
    subtitle: 'Tax Location Details',
  },
  // TAX LOCATION CREATE:
  '/master-data/tax-location/create': {
    clickTo: '/master-data/tax-location',
    title: 'Tax Location',
    type: 'list',
    subtitle: 'Create Tax Location',
  },
  // TAX LOCATION CORRECTION:
  '/master-data/tax-location/correction': {
    clickTo: '/master-data/tax-location/detail',
    title: 'Tax Location Details',
    type: 'detail',
    subtitle: 'Correction Tax Location',
  },
  // TAX LOCATION UPDATE:
  '/master-data/tax-location/update': {
    clickTo: '/master-data/tax-location/detail',
    title: 'Tax Location Details',
    type: 'detail',
    subtitle: 'Update Tax Location',
  },
  '/employee/detail/rehire/create': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Rehire Employee',
  },
  '/employee/detail/rehire/detail': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Rehirement Info Details',
  },
  // TAX-LOCATION
  '/organization/legal-entity/detail/taxlocations/create': {
    clickTo: '/organization/legal-entity/detail/taxlocations',
    title: 'Legal Entity Details',
    type: 'list',
    subtitle: 'Add Tax Location',
  },
  '/organization/legal-entity/detail/taxlocations/update': {
    clickTo: '/organization/legal-entity/detail/taxlocations/detail',
    title: 'Legal Entity Details',
    type: 'list',
    subtitle: 'Update Tax Location',
  },
  '/organization/legal-entity/detail/taxlocations/correction': {
    clickTo: '/organization/legal-entity/detail/taxlocations/detail',
    title: 'Legal Entity Details',
    type: 'list',
    subtitle: 'Correction Tax Location',
  },
  // TERMINATION
  '/employee/detail/termination/create': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Terminate Employee',
  },
  '/employee/detail/termination/update': {
    clickTo: '/termination/detail/',
    title: 'Termination Info Details',
    type: 'detail',
    subtitle: 'Terminate Employee',
  },
  '/employee/detail/termination/detail': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Termination Info Details',
  },
  // BANK MASTER DATA:
  '/master-data/bank/details': {
    clickTo: '/master-data/bank',
    title: 'Bank',
    type: 'list',
    subtitle: 'Bank Details',
  },
  '/master-data/bank/create': {
    clickTo: '/master-data/bank',
    title: 'Bank',
    type: 'list',
    subtitle: 'Create Bank',
  },
  '/master-data/bank/update': {
    clickTo: '/master-data/bank/details',
    title: 'Bank Details',
    type: 'detail',
    subtitle: 'Update Bank',
  },
  // REPORT GENERATION
  '/report/standard-report/generate-1721-i': {
    clickTo: '/report/standard-report?tab=1',
    title: 'Statutory',
    type: 'list',
    subtitle: 'Generate 1721 - I',
  },
  '/report/standard-report/generate-tax-calculation': {
    clickTo: '/report/standard-report?tab=1',
    title: 'Statutory',
    type: 'list',
    subtitle: 'Generate Income Tax Calculation',
  },
  '/report/standard-report/generate-cost-center': {
    clickTo: '/report/standard-report?tab=0',
    title: 'Standard Report',
    type: 'list',
    subtitle: 'Generate Cost Center',
  },
  '/report/standard-report/payroll-register': {
    clickTo: '/report/standard-report?tab=0',
    title: 'Standard Report',
    type: 'list',
    subtitle: 'Generate Payroll Register',
  },
  '/report/standard-report/generate-bpjs': {
    clickTo: '/report/standard-report?tab=1',
    title: 'Statutory',
    type: 'list',
    subtitle: 'Generate BPJS Kesehatan',
  },
  '/report/standard-report/generate-1721-a1': {
    clickTo: '/report/standard-report?tab=1',
    title: 'Statutory',
    type: 'list',
    subtitle: 'Generate 1721-A1',
  },
  '/report/standard-report/generate-bpjs-ketenagakerjaan-baru': {
    clickTo: '/report/standard-report?tab=1',
    title: 'Statutory',
    type: 'list',
    subtitle: 'Generate BPJS Ketenagakerjaan (Baru)',
  },
  '/report/standard-report/generate-bpjs-ketenagakerjaan-lanjutan': {
    clickTo: '/report/standard-report?tab=1',
    title: 'Statutory',
    type: 'list',
    subtitle: 'Generate BPJS Ketenagakerjaan (Lanjutan)',
  },
  '/report/standard-report/generate-e-bupot-2126': {
    clickTo: '/report/standard-report?tab=1',
    title: 'Statutory',
    type: 'list',
    subtitle: 'Generate e-Bupot 21/26',
  },
  '/report/standard-report/generate-overtime': {
    clickTo: '/report/standard-report?tab=1',
    title: 'Standard Report',
    type: 'list',
    subtitle: 'Generate Overtime',
  },
  // REPORT CONFIGURATION
  '/report/bpjs-configuration': {
    clickTo: '/report/standard-report?tab=1',
    title: 'Statutory',
    type: 'list',
    subtitle: 'Configure BPJS Kesehatan',
  },
  '/report/1721-configuration': {
    clickTo: '/report/standard-report?tab=1',
    title: 'Statutory',
    type: 'list',
    subtitle: 'Configure 1721 - I',
  },
  '/report/standard-report/ebupot21-configuration': {
    clickTo: '/report/standard-report?tab=1',
    title: 'Statutory',
    type: 'list',
    subtitle: 'Configure e-Bupot 21/26',
  },
  '/report/standard-report/1721a1-configuration': {
    clickTo: '/report/standard-report?tab=1',
    title: 'Statutory',
    type: 'list',
    subtitle: 'Configure 1721 - A1',
  },
  '/report/standard-report/configuration-bpjs-ketenagakerjaan-baru': {
    clickTo: '/report/standard-report?tab=1',
    title: 'Statutory',
    type: 'list',
    subtitle: 'Configure BPJS Ketenagakerjaan (Baru)',
  },
  '/report/standard-report/configuration-bpjs-ketenagakerjaan-lanjutan': {
    clickTo: '/report/standard-report?tab=1',
    title: 'Statutory',
    type: 'list',
    subtitle: 'Configure BPJS Ketenagakerjaan (Lanjutan)',
  },
  '/import/download-template': {
    clickTo: '/import',
    title: 'Import',
    type: 'list',
    subtitle: 'Download File',
  },
  '/import/import-file': {
    clickTo: '/import',
    title: 'Import',
    type: 'list',
    subtitle: 'Import File',
  },
  '/import/detail': {
    clickTo: '/import',
    title: 'Import',
    type: 'list',
    subtitle: 'Import Details',
  },
  // NEWS
  '/news/detail': {
    clickTo: '/',
    title: 'Home',
    type: 'list',
    subtitle: 'News Details',
  },
  '/news/create': {
    clickTo: '/',
    title: 'Home',
    type: 'list',
    subtitle: 'Create News',
  },
  '/news/update': {
    clickTo: '/news/detail',
    title: 'News Detail',
    type: 'detail',
    subtitle: 'Update News',
  },
  '/news/correction': {
    clickTo: '/news/detail',
    title: 'News Detail',
    type: 'detail',
    subtitle: 'Correct News',
  },
  // BANK-FILE
  '/payroll/configuration/bank-file/create': {
    clickTo: '/payroll/configuration/bank-file',
    title: 'Bank File',
    type: 'list',
    subtitle: 'Create Bank File',
  },
  // CAREER TRANSITION
  '/employee/detail/career-transition': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Career Transition',
  },
  '/employee/detail/career-transition/detail': {
    clickTo: '/employee/detail/career-transition',
    title: 'Career Transition',
    type: 'create',
    subtitle: 'Career Transition Details',
  },
  '/employee/detail/career-transition/create': {
    clickTo: '/employee/detail/:id/career-transition',
    title: 'Career Transition',
    type: 'create',
    subtitle: 'Create Career Transition',
  },
  '/employee/detail/career-transition/create-termination': {
    clickTo: '/employee/detail/:id/career-transition',
    title: 'Career Transition',
    type: 'create',
    subtitle: 'Create Career Transition',
  },

  // WEBESS

  '/ess/employee/payslips': {
    clickTo: '/ess/employee',
    title: 'Home',
    type: 'create',
    subtitle: 'Payslips',
  },
  '/ess/employee/approved-time-off/detail': {
    clickTo: '/ess/employee/approved-time-off',
    title: 'Approved Time Off',
    type: 'list',
    subtitle: 'Approved Time Off Details',
  },
  '/ess/employee/approved-time-off': {
    clickTo: '/ess/employee',
    title: 'Home',
    type: 'list',
    subtitle: 'Approved Time Off',
  },
  '/payroll/payroll-run/severance-pay': {
    clickTo: '/payroll/payroll-run',
    title: 'Run Payroll',
    type: 'list',
    subtitle: 'Severance Pay',
  },
  '/payroll/payroll-run/severance-pay/payment/create': {
    clickTo: '/payroll/payroll-run/severance-pay',
    title: 'Severance Pay',
    type: 'list',
    subtitle: 'Create Severance Pay',
  },
  '/payroll/payroll-run/severance-pay/payment/detail': {
    clickTo: '/payroll/payroll-run/severance-pay',
    title: 'Severance Pay',
    type: 'list',
    subtitle: 'Create Severance Pay',
  },
  '/payroll/payroll-run/severance-pay/plan/create': {
    clickTo: '/payroll/payroll-run/severance-pay',
    title: 'Severance Pay',
    type: 'list',
    subtitle: 'Create Severance Plan',
  },
  '/payroll/payroll-run/severance-pay/plan/detail': {
    clickTo: '/payroll/payroll-run/severance-pay',
    title: 'Severance Pay',
    type: 'list',
    subtitle: 'Severance Plan Details',
  },
  // VIEW-HISTORY
  '/report/standard-report/history-view/bpjs-kesehatan': {
    clickTo: '/report/standard-report?tab=1',
    title: 'Statutory',
    type: 'list',
    subtitle: 'BPJS Kesehatan History',
  },
  '/report/standard-report/history-view/bpjs-tk-baru': {
    clickTo: '/report/standard-report?tab=1',
    title: 'Statutory',
    type: 'list',
    subtitle: 'BPJS Ketenagakerjaan (Baru) History',
  },
  '/report/standard-report/history-view/bpjs-tk-lanjutan': {
    clickTo: '/report/standard-report?tab=1',
    title: 'Statutory',
    type: 'list',
    subtitle: 'BPJS Ketenagakerjaan (Lanjutan) History',
  },
  '/report/standard-report/history-view/1721i': {
    clickTo: '/report/standard-report?tab=1',
    title: 'Statutory',
    type: 'list',
    subtitle: '1721 - I History',
  },
  '/report/standard-report/history-view/1721a1': {
    clickTo: '/report/standard-report?tab=1',
    title: 'Statutory',
    type: 'list',
    subtitle: '1721 - A1 History',
  },
  '/report/standard-report/history-view/e-bupot-2126': {
    clickTo: '/report/standard-report?tab=1',
    title: 'Statutory',
    type: 'list',
    subtitle: 'e-Bupot 21/26 History',
  },
  // TIME ITEM
  '/time/configuration/time-items/create': {
    clickTo: '/time/configuration/time-items',
    title: 'Time Item',
    type: 'list',
    subtitle: 'Create Time Item',
  },
  '/time/configuration/time-items/detail': {
    clickTo: '/time/configuration/time-items',
    title: 'Time Item',
    type: 'list',
    subtitle: 'Time Item Details',
  },
  '/time/configuration/time-items/update': {
    clickTo: '/time/configuration/time-items/detail',
    title: 'Work Day',
    type: 'detail',
    subtitle: 'Update Time Item',
  },
  // TIME EVENT
  '/time/configuration/time-event/create': {
    clickTo: '/time/configuration/time-event',
    title: 'Time Event',
    type: 'list',
    subtitle: 'Create Time Event',
  },
  '/time/configuration/time-event/detail': {
    clickTo: '/time/configuration/time-event',
    title: 'Time Event',
    type: 'list',
    subtitle: 'Time Event Details',
  },
  '/time/configuration/time-event/update': {
    clickTo: '/time/configuration/time-event/detail',
    title: 'Time Event Details',
    type: 'detail',
    subtitle: 'Update Time Event',
  },
  // ATTENDANCE STATUS
  '/time/configuration/attendance-status/create': {
    clickTo: '/time/configuration/attendance-status',
    title: 'Attendance Status',
    type: 'list',
    subtitle: 'Create Attendance Status',
  },
  '/time/configuration/attendance-status/detail': {
    clickTo: '/time/configuration/attendance-status',
    title: 'Attendance Status',
    type: 'list',
    subtitle: 'Attendance Status Details',
  },
  '/time/configuration/attendance-status/update': {
    clickTo: '/time/configuration/attendance-status/detail',
    title: 'Attendance Status Details',
    type: 'detail',
    subtitle: 'Update Attendance Status',
  },
  // SHIFT
  '/time/setup/shift/create': {
    clickTo: '/time/setup/shift',
    title: 'Shift',
    type: 'list',
    subtitle: 'Create Shift',
  },
  '/time/setup/shift/detail': {
    clickTo: '/time/setup/shift',
    title: 'Shift',
    type: 'list',
    subtitle: 'Shift Details',
  },
  '/time/setup/shift/update': {
    clickTo: '/time/setup/shift/detail',
    title: 'Shift Details',
    type: 'detail',
    subtitle: 'Update Shift',
  },
  '/time/setup/shift/correction': {
    clickTo: '/time/setup/shift/detail',
    title: 'Shift Details',
    type: 'detail',
    subtitle: 'Correct Shift',
  },
  // Attendance Process
  '/time/attendance/attendance-process/create': {
    clickTo: '/time/attendance/attendance-process',
    title: 'Attendance Process',
    type: 'list',
    subtitle: 'Create Attendance Process',
  },
  // Schedule
  '/time/setup/schedule/create': {
    clickTo: '/time/setup/schedule',
    title: 'Schedule',
    type: 'list',
    subtitle: 'Create Schedule',
  },
  '/time/setup/schedule/detail': {
    clickTo: '/time/setup/schedule',
    title: 'Schedule',
    type: 'list',
    subtitle: 'Schedule Details',
  },
  '/time/setup/schedule/update': {
    clickTo: '/time/setup/schedule/detail',
    title: 'Schedule Details',
    type: 'detail',
    subtitle: 'Update Schedule',
  },
  '/time/setup/schedule/correction': {
    clickTo: '/time/setup/schedule/detail',
    title: 'Schedule Details',
    type: 'detail',
    subtitle: 'Correct Schedule',
  },
  '/time/attendance/attendance-process/detail': {
    clickTo: '/time/attendance/attendance-process',
    title: 'Attendance Process',
    type: 'list',
    subtitle: 'Attendance Process Details',
  },
  // Time Off
  '/time/setup/time-off/create': {
    clickTo: '/time/setup/time-off',
    title: 'Time Off',
    type: 'list',
    subtitle: 'Create Time Off',
  },
  '/time/setup/time-off/detail': {
    clickTo: '/time/setup/time-off',
    title: 'Time Off',
    type: 'list',
    subtitle: 'Time Off Details',
  },
  // Work Schedule
  '/time/work-schedule/assign-schedule': {
    clickTo: '/time/work-schedule',
    title: 'Work Schedule',
    type: 'list',
    subtitle: 'Assign Schedule',
  },
  // Attendance Machine
  '/time/configuration/attendance-machine/create': {
    clickTo: '/time/configuration/attendance-machine',
    title: 'Attendance Machine',
    type: 'list',
    subtitle: 'Create Attendance Machine',
  },
  '/time/configuration/attendance-machine/detail': {
    clickTo: '/time/configuration/attendance-machine',
    title: 'Attendance Machine',
    type: 'list',
    subtitle: 'Attendance Machine Details',
  },
  '/time/configuration/attendance-machine/update': {
    clickTo: '/time/configuration/attendance-machine/detail',
    title: 'Attendance Machine Details',
    type: 'detail',
    subtitle: 'Update Attendance Machine',
  },
  // HOLIDAY
  '/time/setup/holiday/create': {
    clickTo: '/time/setup/holiday',
    title: 'Holiday',
    type: 'list',
    subtitle: 'Create Holiday',
  },
  '/time/setup/holiday/detail': {
    clickTo: '/time/setup/holiday',
    title: 'Holiday',
    type: 'list',
    subtitle: 'Detail Holiday',
  },
  // Job Site
  '/time/setup/job-site/create': {
    clickTo: '/time/setup/job-site',
    title: 'Job Site',
    type: 'list',
    subtitle: 'Create Job Site',
  },
  '/time/setup/job-site/update': {
    clickTo: '/time/setup/job-site',
    title: 'Job Site',
    type: 'list',
    subtitle: 'Update Job Site',
  },
  // Time off Request Details Emp Profile
  '/employee/detail/request-details': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Request Details',
  },
  //  overtime request employee detail
  '/employee/detail/overtime-history-details': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Request Details',
  },
  // time off request balance
  '/time/time-off-request/request/detail': {
    clickTo: '/time/time-off-request/request',
    title: 'Request',
    type: 'list',
    subtitle: 'Request Details',
  },
  // overtime
  '/time/setup/overtime/create': {
    clickTo: '/time/setup/overtime',
    title: 'Overtime',
    type: 'list',
    subtitle: 'Create Overtime',
  },
  '/time/setup/overtime/assign': {
    clickTo: '/time/setup/overtime',
    title: 'Overtime',
    type: 'list',
    subtitle: 'Overtime Assignment',
  },
  '/time/setup/overtime/detail': {
    clickTo: '/time/setup/overtime',
    title: 'Overtime',
    type: 'list',
    subtitle: 'Overtime Details',
  },
  // Policy
  '/time/configuration/policy/attendance/create': {
    clickTo: '/time/configuration/policy',
    title: 'Policy',
    type: 'list',
    subtitle: 'Create Attendance Policy',
  },
  '/time/configuration/policy/attendance/detail': {
    clickTo: '/time/configuration/policy',
    title: 'Policy',
    type: 'list',
    subtitle: 'Attendance Policy Details',
  },
  '/time/configuration/policy/ess/create': {
    clickTo: '/time/configuration/policy',
    title: 'Policy',
    type: 'list',
    subtitle: 'Create ESS Policy',
  },
  '/time/configuration/policy/ess/detail': {
    clickTo: '/time/configuration/policy',
    title: 'Policy',
    type: 'list',
    subtitle: 'ESS Policy Details',
  },
  // File
  '/organization/file/create': {
    clickTo: '/organization/file',
    title: 'Files',
    type: 'list',
    subtitle: 'Create File',
  },
  // Overtime-item
  '/time/configuration/overtime-item/create': {
    clickTo: '/time/configuration/overtime-item',
    title: 'Overtime Item',
    type: 'list',
    subtitle: 'Create Overtime Item',
  },
  '/time/configuration/overtime-item/detail': {
    clickTo: '/time/configuration/overtime-item',
    title: 'Overtime Item Details',
    type: 'list',
    subtitle: 'Overtime Item Details',
  },
  '/organization/file/correction': {
    clickTo: '/organization/file',
    title: 'Files',
    type: 'list',
    subtitle: 'Correct File',
  },
  '/organization/file/update': {
    clickTo: '/organization/file',
    title: 'Files',
    type: 'list',
    subtitle: 'Update File',
  },
  // overtime request
  '/time/overtime/overtime-request/detail': {
    clickTo: '/time/overtime/overtime-request',
    title: 'Overtime',
    type: 'list',
    subtitle: 'Overtime Details',
  },
  // Ess Access Control
  '/security/ess-access-control/create': {
    clickTo: '/security/ess-access-control',
    title: 'ESS Access Control',
    type: 'list',
    subtitle: 'Create ESS Access Control',
  },
  '/security/ess-access-control/detail': {
    clickTo: '/security/ess-access-control',
    title: 'ESS Access Control',
    type: 'list',
    subtitle: 'ESS Access Control Details',
  },
  '/security/ess-access-control/update': {
    clickTo: '/security/ess-access-control/detail',
    title: 'ESS Access Control Details',
    type: 'detail',
    subtitle: 'Update ESS Access Control',
  },
  // Time Off Item
  '/time/configuration/time-off-items/create': {
    clickTo: '/time/configuration/time-off-items',
    title: 'Time Off Item',
    type: 'list',
    subtitle: 'Create Time Off Item',
  },
  '/time/configuration/time-off-items/detail': {
    clickTo: '/time/configuration/time-off-items',
    title: 'Time Off Item',
    type: 'list',
    subtitle: 'Time Off Item Details',
  },
  // Balance Details
  '/employee/detail/balance-details': {
    clickTo: '/employee/detail',
    title: 'Employee Details',
    type: 'detail',
    subtitle: 'Balance Details',
  },
  '/employee/detail/balance/log-history': {
    clickTo: '/employee/detail',
    title: 'Balance Details',
    type: 'detail',
    subtitle: 'Log History Details',
  },
  // Claim Type
  '/claim/configuration/claim-type/detail': {
    clickTo: '/claim/configuration/claim-type',
    title: 'Claim Type',
    type: 'list',
    subtitle: 'Claim Type Details',
  },
};
