import { RouteNode } from '../../@types';
import { CoinHand } from '../../components/atom/icon';
import { claimType } from './configuration/claimType';

export const claimRoute: RouteNode[] = [
  {
    label: 'Claim',
    icon: CoinHand,
    path: 'claim',
    component: null,
    child: [
      {
        label: 'Configuration',
        path: 'configuration',
        component: null,
        child: [...claimType],
      },
    ],
  },
];
